import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

export default function mailsuccess() {
  return (
      <main>
          <Helmet>
            <meta name={`robots`} content={`noindex, nofollow`} />
          </Helmet>

          <div className='py-10 w-10/12 mx-auto'> 
            <p className='py-4 text-center'>Your Email was Sent Successfully. <br className='lg:hidden'/>We will respond to you as quickly as possible</p>
            <p className='text-center text-sm uppercase text-orange-400'>-  The Verdant Team</p>
          </div>
         
          <Link to="/contact"><div id="cta - back to website" className='my-10 px-6 py-1 w-8/12 mx-auto flex items-center space-x-4 uppercase font-ibmplex text-sm font-bold text-gray-100 bg-green-700 rounded-md shadow-lg lg:w-3/12 lg:mx-auto'>
                <div id="arrow back">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-orange-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>
                </div>
                <div>Back to the Website</div>
            </div></Link>
      </main>
  );
}